import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class PrivacyPolicy extends React.Component {
  render() {
    const siteTitle = 'About J & J Gates Service and Design'
    const siteDescription = 'this.props.data.site.siteMetadata.description'
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Privacy Policy:</h1>
            <hr />
          </div>
        </div>
        <div className="container custom-container pt-5 d-padding-b text-justify">
          <div className="row mb-5">
            <div className="col-md-12">
              <h6>
                J&J AUTOMATIC GATE SERVICE & DESIGN (
                <strong> "J&J AUTOMATIC GATE SERVICE & DESIGN </strong>")
                operates Jjgates.com and may operate other websites. <br /> It
                is J&J AUTOMATIC GATE SERVICE & DESIGN'S policy to respect your
                privacy regarding any information we may collect while operating
                our websites.
              </h6>
              <h3>Website Visitors</h3>
              <p>
                Like most website operators, J&J AUTOMATIC GATE SERVICE & DESIGN
                collects non-personally-identifying information of the sort that
                web browsers and servers typically make available, such as the
                browser type, language preference, referring site, and the date
                and time of each visitor request. J&J AUTOMATIC GATE SERVICE &
                DESIGN'S purpose in collecting non-personally identifying
                information is to better understand how J&J AUTOMATIC GATE
                SERVICE & DESIGN'S visitors use its website. From time to time,
                J&J AUTOMATIC GATE SERVICE & DESIGN may release
                non-personally-identifying information in the aggregate, e.g.,
                by publishing a report on trends in the usage of its website.
              </p>
              <p>
                J&J AUTOMATIC GATE SERVICE & DESIGN also collects potentially
                personally-identifying information like Internet Protocol (IP)
                addresses for logged in users and for users leaving comments on
                Jjgates.com blogs/sites. J&J AUTOMATIC GATE SERVICE & DESIGN
                only discloses logged in user and commenter IP addresses under
                the same circumstances that it uses and discloses
                personally-identifying information as described below, except
                that commenter IP addresses and email addresses are visible and
                disclosed to the administrators of the blog/site where the
                comment was left.
              </p>
              <h3>Gathering of Personally-Identifying Information</h3>
              <p>
                Certain visitors to J&J AUTOMATIC GATE SERVICE & DESIGN'S
                websites choose to interact with J&J AUTOMATIC GATE SERVICE &
                DESIGN in ways that require J&J AUTOMATIC GATE SERVICE & DESIGN
                to gather personally-identifying information. The amount and
                type of information that J&J AUTOMATIC GATE SERVICE & DESIGN
                gathers depends on the nature of the interaction. For example,
                we ask visitors who sign up at <Link to="/">Jjgates.com</Link>{' '}
                to provide a username and email address. Those who engage in
                transactions with J&J AUTOMATIC GATE SERVICE & DESIGN are asked
                to provide additional information, including as necessary the
                personal and financial information required to process those
                transactions. In each case, J&J AUTOMATIC GATE SERVICE & DESIGN
                collects such information only insofar as is necessary or
                appropriate to fulfill the purpose of the visitor's interaction
                with J&J AUTOMATIC GATE SERVICE & DESIGN. J&J AUTOMATIC GATE
                SERVICE & DESIGN does not disclose personally-identifying
                information other than as described below. And visitors can
                always refuse to supply personally-identifying information, with
                the caveat that it may prevent them from engaging in certain
                website-related activities.
              </p>
              <h3>Aggregated Statistics</h3>
              <p>
                J&J AUTOMATIC GATE SERVICE & DESIGN may collect statistics about
                the behavior of visitors to its websites. J&J AUTOMATIC GATE
                SERVICE & DESIGN may display this information publicly or
                provide it to others. However, J&J AUTOMATIC GATE SERVICE &
                DESIGN does not disclose personally-identifying information
                other than as described below.
              </p>
              <h3>Protection of Certain Personally-Identifying Information</h3>
              <p>
                J&J AUTOMATIC GATE SERVICE & DESIGN discloses potentially
                personally-identifying and personally-identifying information
                only to those of its employees, contractors and affiliated
                organizations that (i) need to know that information in order to
                process it on J&J AUTOMATIC GATE SERVICE & DESIGN'S behalf or to
                provide services available at J&J AUTOMATIC GATE SERVICE &
                DESIGN'S websites, and (ii) that have agreed not to disclose it
                to others. Some of those employees, contractors and affiliated
                organizations may be located outside of your home country; by
                using J&J AUTOMATIC GATE SERVICE & DESIGN'S websites, you
                consent to the transfer of such information to them. J&J
                AUTOMATIC GATE SERVICE & DESIGN will not rent or sell
                potentially personally-identifying and personally-identifying
                information to anyone. Other than to its employees, contractors
                and affiliated organizations, as described above, J&J AUTOMATIC
                GATE SERVICE & DESIGN discloses potentially
                personally-identifying and personally-identifying information
                only in response to a subpoena, court order or other
                governmental request, or when J&J AUTOMATIC GATE SERVICE &
                DESIGN believes in good faith that disclosure is reasonably
                necessary to protect the property or rights of J&J AUTOMATIC
                GATE SERVICE & DESIGN, third parties or the public at large. If
                you are a registered user of an J&J AUTOMATIC GATE SERVICE &
                DESIGN website and have supplied your email address, J&J
                AUTOMATIC GATE SERVICE & DESIGN may occasionally send you an
                email to tell you about new features, solicit your feedback, or
                just keep you up to date with what's going on with J&J AUTOMATIC
                GATE SERVICE & DESIGN and our products. If you send us a request
                (for example via email or via one of our feedback mechanisms),
                we reserve the right to publish it in order to help us clarify
                or respond to your request or to help us support other users.
                J&J AUTOMATIC GATE SERVICE & DESIGN takes all measures
                reasonably necessary to protect against the unauthorized access,
                use, alteration or destruction of potentially
                personally-identifying and personally-identifying information.
              </p>
              <h3>Cookies</h3>
              <p>
                A cookie is a string of information that a website stores on a
                visitor's computer, and that the visitor's browser provides to
                the website each time the visitor returns. J&J AUTOMATIC GATE
                SERVICE & DESIGN uses cookies to help J&J AUTOMATIC GATE SERVICE
                & DESIGN identify and track visitors, their usage of J&J
                AUTOMATIC GATE SERVICE & DESIGN website, and their website
                access preferences. J&J AUTOMATIC GATE SERVICE & DESIGN visitors
                who do not wish to have cookies placed on their computers should
                set their browsers to refuse cookies before using J&J AUTOMATIC
                GATE SERVICE & DESIGN'S websites, with the drawback that certain
                features of J&J AUTOMATIC GATE SERVICE & DESIGN'S websites may
                not function properly without the aid of cookies.
              </p>
              <h3>Business Transfers</h3>
              <p>
                If J&J AUTOMATIC GATE SERVICE & DESIGN, or substantially all of
                its assets, were acquired, or in the unlikely event that J&J
                AUTOMATIC GATE SERVICE & DESIGN goes out of business or enters
                bankruptcy, user information would be one of the assets that is
                transferred or acquired by a third party. You acknowledge that
                such transfers may occur, and that any acquirer of J&J AUTOMATIC
                GATE SERVICE & DESIGN may continue to use your personal
                information as set forth in this policy.
              </p>
              <h3>Ads</h3>
              <p>
                Ads appearing on any of our websites may be delivered to users
                by advertising partners, who may set cookies. These cookies
                allow the ad server to recognize your computer each time they
                send you an online advertisement to compile information about
                you or others who use your computer. This information allows ad
                networks to, among other things, deliver targeted advertisements
                that they believe will be of most interest to you. This Privacy
                Policy covers the use of cookies by J&J AUTOMATIC GATE SERVICE &
                DESIGN and does not cover the use of cookies by any advertisers.
              </p>
              <h3>Privacy Policy Changes</h3>
              <p>
                Although most changes are likely to be minor, J&J AUTOMATIC GATE
                SERVICE & DESIGN may change its Privacy Policy from time to
                time, and in J&J AUTOMATIC GATE SERVICE & DESIGN'S sole
                discretion. J&J AUTOMATIC GATE SERVICE & DESIGN encourages
                visitors to frequently check this page for any changes to its
                Privacy Policy. If you have a Jjgates.com account, you might
                also receive an alert informing you of these changes. Your
                continued use of this site after any change in this Privacy
                Policy will constitute your acceptance of such change.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PrivacyPolicy

export const PrivacyPolicyPageQuery = graphql`
  query PrivacyPolicyPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
